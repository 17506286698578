import { WorkerStatusFilter } from 'api/assignment/getCalendarEvents'

export const assignmentKey = ['assignment']

export const assignmentDetailKey = (assignmentId: number | string) => [
  ...assignmentKey,
  assignmentId.toString(),
]

export const assignmentCalendarEventsKey = (
  assignmentId: number | string,
  date: Date,
  workStatus: WorkerStatusFilter
) => [
  ...assignmentKey,
  assignmentId.toString(),
  'calendar-events',
  date.toISOString(),
  workStatus,
]
