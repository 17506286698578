import React, { useEffect } from 'react'
import { Heading } from '@workwhile/ui'
import { CategoryGroupList } from './CategoryGroupList'
import { PositionTemplateProvider } from './PositionTemplateContext'
import { SearchBar } from './SearchBar'
import { TemplateList } from './TemplateList'
import { track } from 'lib/amplitude'

export interface PositionTemplatePickerProps {
  positionTemplateId?: number
  onSelect: (positionTemplateId: number) => void
}

export function PositionTemplatePicker({
  positionTemplateId,
  onSelect,
}: PositionTemplatePickerProps) {
  useEffect(() => {
    track('impression, new_position_template_piker')
  }, [])

  return (
    <PositionTemplateProvider
      onSelect={onSelect}
      selectedTemplateId={positionTemplateId}
    >
      <Heading level={3} m={0}>
        Select a Position
      </Heading>
      <SearchBar />
      <CategoryGroupList />
      <TemplateList />
    </PositionTemplateProvider>
  )
}
