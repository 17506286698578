import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'
import { useCalendarEventsQuery } from 'queries/assignment/useCalendarEventsQuery'
import { addDays, startOfWeek, subDays } from 'date-fns'
import { useWeekStartState } from './useWeekStartState'
import {
  CalendarViewListing,
  CalendarViewShift,
  CalendarViewWorker,
  CalendarViewWorkerFilter,
} from 'pages/AssignmentDetailPage/types'
import { useSearchParams } from 'react-router-dom'

export type CalendarViewContextState = {
  actions: {
    goToNextWeek: () => void
    goToPreviousWeek: () => void
    goToToday: () => void
    setWorkerFilter: (filter: CalendarViewWorkerFilter) => void
  }
  state: {
    workerFilter: CalendarViewWorkerFilter
    currentWeekStart: Date
    daysOfWeek: Date[]
    shiftsByDay: Record<
      string /** yyyy-MM-dd */,
      Pick<CalendarViewShift, 'id'>[]
    >
    shiftsById: Record<CalendarViewShift['id'], CalendarViewShift>
    workers: CalendarViewWorker[]
    listings: CalendarViewListing[]
    queryStatus: {
      isSuccess: boolean
      isLoading: boolean
      isError: boolean
    }
  }
}

export const CalendarViewContext =
  createContext<CalendarViewContextState | null>(null)

export const useCalendarViewContextStateValue = () => {
  const context = useContext(CalendarViewContext)
  if (!context) {
    throw new Error(
      'useCalendarViewContextStateValue must be used within a <CalendarViewProvider />'
    )
  }
  return context
}

type CalendarViewProviderProps = PropsWithChildren

const WORKER_ASSIGNMENT_STATUS = 'workstatus'
const mapWorkerFilterToStatus = {
  active: 'assigned',
  unscheduled: 'unassigned',
  all: 'all',
} as const

/**
 * Provides the state for the calendar view such as the current week start, days of the week, shifts by date, etc.
 *
 * Does not provide the state for any assignment related workflows such as the request workers, etc.
 *
 * The goal is to use this provider for the calendar view and then have a separate provider for the assignment workflows.
 */
export const CalendarViewProvider = ({
  children,
}: CalendarViewProviderProps) => {
  const [currentWeekStart, setCurrentWeekStart] = useWeekStartState()
  const [params, setParams] = useSearchParams()
  const workerFilter = params.get(
    WORKER_ASSIGNMENT_STATUS
  ) as CalendarViewWorkerFilter

  const setWorkerFilter = (value: CalendarViewWorkerFilter) => {
    setParams({
      [WORKER_ASSIGNMENT_STATUS]: value,
    })
  }

  const { assignment } = useAssignmentDetailContextStateValue()

  const {
    data: calendarEventsData,
    isSuccess,
    isLoading,
    isError,
  } = useCalendarEventsQuery({
    assignmentId: assignment.id,
    startDate: currentWeekStart,
    endDate: addDays(currentWeekStart, 6),
    workStatus: mapWorkerFilterToStatus[workerFilter],
  })

  const daysOfWeek = useMemo(() => {
    return Array.from({ length: 7 }, (_, i) => addDays(currentWeekStart, i))
  }, [currentWeekStart])

  const value: CalendarViewContextState = {
    actions: {
      goToNextWeek: () => {
        setCurrentWeekStart(addDays(currentWeekStart, 7))
      },
      goToPreviousWeek: () => {
        setCurrentWeekStart(subDays(currentWeekStart, 7))
      },
      goToToday: () => {
        setCurrentWeekStart(startOfWeek(new Date(), { weekStartsOn: 0 }))
      },
      setWorkerFilter,
    },
    state: {
      currentWeekStart,
      daysOfWeek,
      shiftsById: calendarEventsData?.shiftsById || {},
      shiftsByDay: calendarEventsData?.shiftsByDay || {},
      workers: calendarEventsData?.workers || [],
      listings: calendarEventsData?.listings || [],
      queryStatus: {
        isSuccess,
        isLoading,
        isError,
      },
      workerFilter,
    },
  }

  return (
    <CalendarViewContext.Provider value={value}>
      {children}
    </CalendarViewContext.Provider>
  )
}
