import {
  isWorkRowUnassigned,
  shouldShowApproveButton,
  shouldShowNeedsAttentionLabel,
} from 'lib/work'
import React, { useMemo, useState } from 'react'
import { Box, Button, Flex, Text, Tooltip } from 'ui'
import { useAppTheme } from '@workwhile/ui'
import { useShiftDetail } from '../useShiftDetail'
import { ShiftDetailAssignedWorkerRow } from './ShiftDetailAssignedWorkerRow'
import { ShiftDetailUnfilledWorkerRow } from './ShiftDetailUnfilledWorkerRow'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, FormProvider } from 'react-hook-form'
import {
  BulkWorkApprovalForm,
  bulkWorkApprovalSchema,
} from '../schemas/workApprovalSchema'
import {
  BulkApprovalWorkError,
  useBulkSaveWorkMutation,
} from 'queries/work/useBulkSaveWorkMutation'
import { toServerData } from '../dataTransformers'
import { Work } from 'typings/common_defs'
import { SpinningRefreshIcon } from './SpinningRefreshIcon'

interface Props {
  showBulkApproveButton?: boolean
  showUnfilledRows?: boolean
  showNeedsAttentionOnly?: boolean
  showNeedsApprovalOnly?: boolean
  showWorkerLabel?: boolean
  showWorkerStatusBadge?: boolean
}

export const ShiftDetailAssignedWorkers = ({
  showBulkApproveButton = true,
  showUnfilledRows = true,
  showNeedsAttentionOnly = false,
  showNeedsApprovalOnly = false,
  showWorkerLabel = true,
  showWorkerStatusBadge = true,
}: Props) => {
  const { shift, assignedWorkRows, isFetching } = useShiftDetail()
  const [error, setError] = useState<string | null>(null)
  const { colors } = useAppTheme()

  const methods = useForm<BulkWorkApprovalForm>({
    resolver: zodResolver(bulkWorkApprovalSchema),
    mode: 'onBlur',
    defaultValues: assignedWorkRows.filter(
      (work) => !isWorkRowUnassigned(work) // we don't need the skeleton (i.e. unassigned) rows in the form
    ),
  })

  const { mutate: bulkApprove, isPending: isSubmitting } =
    useBulkSaveWorkMutation({
      shiftId: shift?.id || -1,
      onSuccess: (result: BulkApprovalWorkError[]) => {
        for (const r of result) {
          if (r.succeeded) continue
          const index = assignedWorkRows.findIndex(
            (w) => Number(w.id) === Number(r.work_id)
          )
          const dataPath = `${index}`
          methods.setError(dataPath as any, {
            // figure out typing later
            type: 'manual',
            message: r.error_message,
          })
        }
      },
      onError: (err) => setError(err.message),
    })

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setError(null)
    methods.clearErrors()

    const values = methods.getValues()
    const formData = Object.values(values)

    // Filter to get finished and unapproved work
    const finishedUnapprovedWork = formData.filter((work) =>
      shouldShowApproveButton(work as Work)
    )

    // Validate with Zod schema
    const result = bulkWorkApprovalSchema.safeParse(finishedUnapprovedWork)

    if (!result.success) {
      setError(result.error.toString())
      return
    }

    const submissionData = result.data.map((work) => toServerData(work))

    bulkApprove(submissionData)
  }

  const showApproveAllButton: boolean = useMemo(() => {
    if (!showBulkApproveButton) return false
    if (shift?.isTryout) return false
    // if there:
    // - is at least 1 finished work that hasn't been approved
    // - and there is more than 1 worker assigned to the shift
    // this should be true
    return (
      assignedWorkRows.some((work) => shouldShowApproveButton(work)) &&
      assignedWorkRows.map((work) => work?.worker?.id).length > 1
    )
  }, [assignedWorkRows])

  const shouldDisplayWorkRow = (work: Work) => {
    if (!showUnfilledRows && work.id.toString().includes('skeleton')) {
      return false
    }
    if (showNeedsAttentionOnly && !shouldShowNeedsAttentionLabel(work)) {
      return false
    }
    if (showNeedsApprovalOnly && !shouldShowApproveButton(work)) {
      return false
    }
    return true
  }

  const filteredAssignedWorkRows = useMemo(() => {
    return assignedWorkRows.filter((work) => shouldDisplayWorkRow(work))
  }, [
    assignedWorkRows,
    showUnfilledRows,
    showNeedsAttentionOnly,
    showNeedsApprovalOnly,
  ])

  if (!shift) return null

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        <Box>
          {showApproveAllButton ? (
            <Flex
              flexDirection="row"
              justifyContent={['flex-start', 'flex-start', 'flex-end']}
              alignItems="center"
              mb={3}
            >
              {isFetching ? (
                <Box mr={3}>
                  <Tooltip content={'Updating shift data...'}>
                    <SpinningRefreshIcon size={20} color={colors.info} />
                  </Tooltip>
                </Box>
              ) : null}
              <Flex
                flexDirection="column"
                alignItems={['flex-start', 'flex-start', 'flex-end']}
              >
                <Button
                  kind="medium"
                  variant="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || isFetching}
                >
                  Approve All Finished Work
                </Button>
                {error ? (
                  <Flex mt={3} flexDirection="row" alignItems="flex-start">
                    <Text color="errors.200" fontWeight="bold" mr={1}>
                      Attention:{' '}
                    </Text>
                    <Text color="errors.200">{error}</Text>
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
          ) : null}
          {filteredAssignedWorkRows.map(
            (
              work,
              idx // each of these rows is a page in the form
            ) => (
              <Box
                key={`filtered-assigned-work-row-${work.id}`}
                borderBottom={
                  idx === filteredAssignedWorkRows.length - 1
                    ? null
                    : '1px solid'
                } // don't show border on last row
                borderBottomColor={'neutrals.200'}
                pb={3}
                mb={idx === filteredAssignedWorkRows.length - 1 ? null : 3}
              >
                {work?.worker?.name.includes('skeleton') ? (
                  <ShiftDetailUnfilledWorkerRow
                    key={`shift-detail-unfilled-worker-row-${work.id}`}
                    inactive={work.status === 'pending'}
                  />
                ) : (
                  <ShiftDetailAssignedWorkerRow
                    key={`shift-detail-assigned-worker-row-${work.id}`}
                    work={work}
                    showWorkerLabel={showWorkerLabel}
                    showWorkerStatusBadge={showWorkerStatusBadge}
                  />
                )}
              </Box>
            )
          )}
        </Box>
      </form>
    </FormProvider>
  )
}
