import React from 'react'
import { Box, Flex, Text } from '@workwhile/ui'
import { useCalendarViewContextStateValue } from '../../context'
import { ShiftCell } from './ShiftCell'
import { format } from 'date-fns'

export const ShiftRow = () => {
  const {
    state: { daysOfWeek, shiftsById, shiftsByDay },
  } = useCalendarViewContextStateValue()

  return (
    <tr>
      <Box as="th" scope="row" verticalAlign="middle">
        <Text fontSize={16}>Shifts</Text>
      </Box>
      {daysOfWeek.map((date) => {
        const day = format(date, 'yyyy-MM-dd')

        if (!shiftsByDay[day]) {
          return <Box as="td" p={4} key={`shifts-${day}-empty-cell`} />
        }

        return (
          <Box as="td" key={`shifts-wrapper-${day}`} verticalAlign="top">
            <Flex
              flexDirection="column"
              p={2}
              style={{
                gap: 8,
              }}
              alignItems="flex-start"
            >
              {shiftsByDay[day]?.map(({ id }) => (
                <ShiftCell key={`shifts-work-${id}`} shift={shiftsById[id]} />
              ))}
            </Flex>
          </Box>
        )
      })}
    </tr>
  )
}
