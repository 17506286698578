import React from 'react'
import { Box, Flex, Text, Label } from '@workwhile/ui'
import { usePositionTemplateContext } from './PositionTemplateContext'
import styled from 'styled-components'
import { track } from 'lib/amplitude'

const Divider = styled(Box).attrs({
  flex: 1,
  borderBottom: '1px solid',
  borderColor: 'offWhite',
})``

export function CategoryGroupList() {
  const {
    selectedGroupId,
    setSelectedGroupId,
    allGroups,
    keyword,
    setKeyword,
    visibleTemplates,
  } = usePositionTemplateContext()

  const onGroupClick = (id: number) => {
    if (id === selectedGroupId) {
      setSelectedGroupId(undefined)
      track('button_click, filter_position_templates_by_group_unselect')
    } else {
      setKeyword('')
      setSelectedGroupId(id)
      track('button_click, filter_position_templates_by_group_select')
    }
  }

  if (keyword && visibleTemplates.length > 0) {
    return null
  }

  return (
    <>
      <Flex alignItems={'center'} mt={2}>
        <Divider />
        <Text color={'lightText'} fontSize={1} px={2}>
          or filter by category
        </Text>
        <Divider />
      </Flex>
      <Flex flexWrap={'nowrap'} py={3} gap={2} overflowX={'auto'}>
        {allGroups.map((group) => (
          <Label
            variant={group.id === selectedGroupId ? 'primary' : 'none'}
            key={group.id}
            onClick={() => onGroupClick(group.id)}
            style={{ cursor: 'pointer' }}
            flexShrink={0}
          >
            {group.name}
          </Label>
        ))}
      </Flex>
    </>
  )
}
