import { Box, Text } from '@workwhile/ui'
import { format, isToday } from 'date-fns'
import React from 'react'
import { useCalendarViewContextStateValue } from './context'

type WeekHeadingProps = {
  date: Date
}

const WeekHeading = ({ date }: WeekHeadingProps) => {
  const dayOfTheWeek = format(date, 'E')
  const dayOfTheMonth = format(date, 'd')
  const isTodayValue = isToday(date)
  return (
    <Box as="th" scope="col" p={2} data-active={isTodayValue}>
      <Text color={isTodayValue ? 'primary' : 'text'}>{dayOfTheWeek}</Text>
      <Text color={isTodayValue ? 'primary' : 'lightText'}>
        {dayOfTheMonth}
      </Text>
    </Box>
  )
}

export const WeekHeadings = () => {
  const {
    state: { daysOfWeek },
  } = useCalendarViewContextStateValue()

  const weekHeadings = daysOfWeek.map((day) => (
    <WeekHeading key={`${day.toISOString()}-week-heading`} date={day} />
  ))

  return (
    <thead>
      <tr>
        <th />
        {weekHeadings}
      </tr>
    </thead>
  )
}
