import React from 'react'
import { Box, Button, Flex, Heading, Text } from '@workwhile/ui'
import { PositionTemplate } from 'typings/common_defs'
import { usePositionTemplateContext } from './PositionTemplateContext'
import { track } from 'lib/amplitude'

export function TemplateItem({ template }: { template: PositionTemplate }) {
  const {
    selectedTemplateId,
    setSelectedTemplateId,
    keyword,
    selectedGroupId,
  } = usePositionTemplateContext()
  const isSelected = selectedTemplateId === template.id

  const onSelectClick = () => {
    if (template.id) {
      setSelectedTemplateId(template.id)
      track('button_click, select_position_template', {
        selectedGroupId,
        keyword,
      })
    }
  }

  return (
    <Box py={3} borderBottom={'1px solid'} borderColor={'offWhite'}>
      <Flex alignItems={'center'}>
        <Box flex={1}>
          <Heading level={4} my={0}>
            {template.name}
          </Heading>
          {/* TODO: Add description */}
          {/* <Text color={'lightText'}>Description goes here</Text> */}
        </Box>
        <Box>
          {isSelected ? (
            <Flex alignItems={'center'}>
              <Text color={'primary'} fontWeight={'bold'} mr={2}>
                Selected
              </Text>
            </Flex>
          ) : (
            <Button
              variant={'secondary'}
              kind={'medium'}
              onClick={onSelectClick}
            >
              Select
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
