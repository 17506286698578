import React from 'react'
import { useEditorContext } from './hooks/useEditorContext'
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  MarkdownEditor,
  MarkdownProvider,
  Message,
} from '@workwhile/ui'
import { Paginator } from './Paginator'
import { Preview } from './Preview'
import { useDebounceFn } from 'ahooks'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
import { SaveBox } from './SaveBox'
import { TutorialCategory } from 'api/shift'

export function ContentEditor() {
  const { value: enableQuiz } = useGate(FeatureGates.EnableNewInstructionsQuiz)
  const {
    tutorialId,
    content,
    category,
    setContent,
    quiz,
    generateQuiz,
    editorDefaultValue,
    currentPageIdx,
    setCurrentPageIdx,
    setTabValue,
  } = useEditorContext()
  const enableSignature = category === TutorialCategory.General

  const { run: onEditorChange } = useDebounceFn(
    (value: string) => {
      setContent((prevContent) =>
        prevContent.map((c, idx) => {
          if (idx === currentPageIdx) {
            return { ...c, markdown: value }
          }
          return c
        })
      )
    },
    { wait: 300 }
  )

  const handleRequireSignatureChange = (checked: boolean) => {
    setContent((prevContent) =>
      prevContent.map((c, idx) => {
        if (idx === currentPageIdx && c.require_signature !== checked) {
          return { ...c, require_signature: checked }
        }
        return c
      })
    )
  }

  return (
    <>
      <Heading level={3}>Edit Your Instruction</Heading>
      <Flex>
        <Box flex={1} mr={[0, 0, 0, 4, 7]}>
          <Paginator
            pages={content}
            currentPageIdx={currentPageIdx}
            onPageChange={setCurrentPageIdx}
            onAddPage={() => {
              setContent([...content, { markdown: '' }])
              setCurrentPageIdx(content.length)
            }}
            onDeletePage={(pageIdx) => {
              const newContent = content.filter((_, idx) => idx !== pageIdx)
              setContent(newContent)
              if (currentPageIdx >= newContent.length - 1) {
                setCurrentPageIdx(newContent.length - 1)
              }
            }}
          />
          {content.length > 0 ? (
            <MarkdownProvider key={`page-${currentPageIdx}`}>
              <MarkdownEditor
                minHeight={400}
                defaultValue={editorDefaultValue}
                onChange={onEditorChange}
              />
              {enableSignature ? (
                <Box mt={4}>
                  <Checkbox
                    defaultChecked={
                      !!content[currentPageIdx]?.require_signature
                    }
                    onChange={handleRequireSignatureChange}
                  >
                    Require Worker's Signature
                  </Checkbox>
                </Box>
              ) : null}
            </MarkdownProvider>
          ) : null}
          {/* quiz is not supported on creation, only edit */}
          {!quiz && enableQuiz && tutorialId ? (
            <Message mt={4} variant={'info'}>
              Want to make sure workers understand requirements when accepting
              the shift?&nbsp;
              <a
                onClick={() => {
                  generateQuiz()
                  setTabValue('quiz')
                }}
              >
                Auto Generate Quiz
              </a>
            </Message>
          ) : null}
          <SaveBox />
        </Box>
        <Box display={['none', 'none', 'none', 'block']}>
          <Box position={'sticky'} top={60}>
            <Preview
              content={content[currentPageIdx]?.markdown}
              requireSignature={!!content[currentPageIdx]?.require_signature}
            />
          </Box>
        </Box>
      </Flex>
    </>
  )
}
