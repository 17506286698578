import { useMemo } from 'react'
import { usePositionTemplates } from 'queries/company'

export function usePositionTemplate(positionTemplateId?: number) {
  const { data } = usePositionTemplates()

  return useMemo(() => {
    if (!positionTemplateId || !data) {
      return null
    }

    return data.find(
      (template) => template.id.toString() === positionTemplateId.toString()
    )
  }, [positionTemplateId, data])
}
