import React from 'react'
import { Box, Flex } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import useShiftTimeline from 'hooks/useShiftTimeline'
import { Avatar } from '@workwhile/ui'
import { Search } from 'lucide-react'

export const ShiftDetailUnfilledWorkerRow = ({
  inactive,
}: {
  inactive?: boolean
}) => {
  const { shift } = useShiftDetail()
  const { isInProgress, isInPast } = useShiftTimeline(shift)

  if (!shift) return null

  function getContent() {
    if (isInProgress || isInPast || inactive) {
      return 'Unassigned'
    }

    return 'This spot is being offered to qualified workers'
  }

  return (
    <Box>
      <Flex flexDirection="row" alignItems="center">
        <Box mr={3}>
          <Avatar
            size="large"
            badge={{
              icon: <Search size={9} color={'neutrals.00'} />,
              color: 'neutrals.300',
            }}
          />
        </Box>
        <Box mr={3}>{getContent()}</Box>
      </Flex>
    </Box>
  )
}
