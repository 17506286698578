import React from 'react'
import { Box, MarkdownEditor, MarkdownProvider, Text } from '@workwhile/ui'
import IphoneMockup from 'components/IphoneMockup'

interface Props {
  content?: string
  requireSignature?: boolean
}

export function Preview({ content, requireSignature }: Props) {
  return (
    <IphoneMockup>
      <MarkdownProvider>
        <MarkdownEditor
          defaultValue={content}
          readonly={true}
          borderless={true}
          scale={0.9}
        />
      </MarkdownProvider>
      {requireSignature ? (
        <Box
          mx={3}
          mt={2}
          mb={4}
          border={'2px dashed'}
          borderColor={'info50'}
          borderRadius={'standard'}
          p={3}
        >
          <Text>Signature Collection Pad</Text>
        </Box>
      ) : null}
    </IphoneMockup>
  )
}
