import React, { useMemo } from 'react'
import {
  CalendarViewContextState,
  useCalendarViewContextStateValue,
} from '../../context'
import { format } from 'date-fns'
import { WorkerCardCell } from './WorkerCardCell'

import { Box, Flex } from '@workwhile/ui'
import { WorkerShiftCell } from './WorkerShiftCell'
import { groupBy } from 'ramda'
import { CalendarViewListing } from 'pages/AssignmentDetailPage/types'
import { getShiftDate } from 'pages/AssignmentDetailPage/utils/getShiftDate'

type WorkerRowProps = {
  worker?: CalendarViewContextState['state']['workers'][number]
  shiftsByDay:
    | CalendarViewContextState['state']['shiftsByDay']
    | Partial<Record<string /** yyyy-MM-dd */, CalendarViewListing['shifts']>>
}

const WorkerRow = ({ worker, shiftsByDay }: WorkerRowProps) => {
  const {
    state: { daysOfWeek, shiftsById },
  } = useCalendarViewContextStateValue()

  const workerCells = daysOfWeek.map((day) => {
    const dateString = format(day, 'yyyy-MM-dd')

    const shiftCells =
      shiftsByDay[dateString]?.map(({ id }) => {
        const shift = shiftsById[id]
        const work = worker && shift?.workByWorker?.[worker.id]

        return !worker || work ? (
          <WorkerShiftCell
            key={`shift-${dateString}-${shift.id}`}
            work={work}
            shift={shiftsById[shift.id]}
          />
        ) : (
          <Box key={`shift-${dateString}-placeholder`} as="td" p={3} />
        )
      }) || []

    return (
      <Box as="td" verticalAlign="top" key={`shifts-${dateString}`}>
        <Flex
          display="flex"
          flexDirection="column"
          p={2}
          gap={8}
          alignItems="flex-start"
        >
          {shiftCells}
        </Flex>
      </Box>
    )
  })

  return (
    <tr>
      <WorkerCardCell worker={worker} />
      {workerCells}
    </tr>
  )
}

export const WorkerRows = () => {
  const {
    state: { workers, listings, shiftsByDay, shiftsById, workerFilter },
  } = useCalendarViewContextStateValue()

  const workerRows = useMemo(() => {
    const rows = workers.map((worker) => {
      return (
        <WorkerRow
          key={`workers-${worker.id}`}
          worker={worker}
          shiftsByDay={shiftsByDay}
        />
      )
    })
    return rows
  }, [workers])

  const slotRows = useMemo(() => {
    return listings.map((listing) => {
      const shifts = groupBy(
        ({ id }) => getShiftDate(shiftsById[Number(id)]),
        listing.shifts
      )

      return new Array(listing.numWorkersPending).fill(null).map((_, index) => {
        return (
          <WorkerRow key={`slot-${listing.id}-${index}`} shiftsByDay={shifts} />
        )
      })
    })
  }, [listings])

  return (
    <>
      {workerRows}
      {workerFilter !== 'unscheduled' && slotRows}
    </>
  )
}
