import React, { useState } from 'react'
import { Page } from '../layout'
import {
  Box,
  Tabs,
  Flex,
  Text,
  useAppTheme,
  Modal,
  IconButton,
  Table,
} from 'ui'
import { OutstandingInvoices } from './OutstandingInvoices'
import { PaidInvoices } from './PaidInvoices'
import { ChargeAccounts } from './ChargeAccounts'
import { useCompanyInfo } from 'queries/company'
import { ChargeLimits } from './ChargeLimits'
import { useSearchParams } from 'react-router-dom'
import { LucideInfo } from 'lucide-react'

export function PaymentPage() {
  const { data: company } = useCompanyInfo()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get('type') ?? 'outstanding'
  const { colors } = useAppTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Page title={'Payment'}>
      {!company?.chargeUpfront && (
        <Flex alignItems="center" mb={2}>
          <Text color="info" fontSize="sm">
            FAQ: Invoicing Schedule
          </Text>
          <IconButton
            ml={2}
            size="small"
            aria-label="View invoicing schedule information"
            onClick={() => setIsModalOpen(true)}
          >
            <LucideInfo size={16} color={colors.info} />
          </IconButton>
        </Flex>
      )}
      <Box mt={[0, 1, 3]} mb={[2, 4]}>
        <Tabs
          value={selectedTab}
          onValueChange={(value) => setSearchParams({ type: value })}
          tabs={[
            {
              value: 'outstanding',
              label: `${
                company?.chargeUpfront ? 'Pending' : 'Outstanding'
              } Invoices`,
            },
            {
              value: 'paid',
              label: 'Past Invoices',
            },
          ]}
        >
          <Tabs.Content value="outstanding">
            <OutstandingInvoices />
          </Tabs.Content>
          <Tabs.Content value={'paid'}>
            <PaidInvoices />
          </Tabs.Content>
        </Tabs>
      </Box>
      <ChargeAccounts />
      <ChargeLimits />

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Invoicing Schedule"
      >
        <Text fontSize="lg" mb={3}>
          We issue 4 invoices per month, each covering:
        </Text>
        <Table
          columns={[
            { header: 'Cycle', accessorKey: 'cycle' },
            { header: 'Period', accessorKey: 'period' },
          ]}
          data={[
            { cycle: '1', period: 'Day 1–7' },
            { cycle: '2', period: 'Day 8–14' },
            { cycle: '3', period: 'Day 15–21' },
            { cycle: '4', period: 'Day 22–31' },
          ]}
        />
        <Text fontSize="md" mt={3}>
          Invoices are sent 2–3 business days after each cycle ends.
        </Text>
      </Modal>
    </Page>
  )
}
