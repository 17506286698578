import { useQuery } from '@tanstack/react-query'
import { getCalendarEvents } from 'api/assignment/getCalendarEvents'
import { assignmentCalendarEventsKey } from './keys'

export const useCalendarEventsQuery = (
  request: Parameters<typeof getCalendarEvents>[0]
) => {
  return useQuery({
    queryKey: assignmentCalendarEventsKey(
      request.assignmentId,
      request.startDate,
      request.workStatus
    ),
    queryFn: () => getCalendarEvents(request),
  })
}
