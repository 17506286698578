import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import { getShiftTimezone } from 'lib/shift'
import { WorkShift } from 'typings/common_defs'

export const getShiftDate = (
  shift: Pick<WorkShift, 'startsAt' | 'location'>
): string => {
  if (!shift.startsAt) {
    throw new Error('startsAt is required')
  }
  const timezone = getShiftTimezone(shift)
  const formattedDate = formatInTimeZone(shift.startsAt, timezone, 'yyyy-MM-dd')
  return formattedDate
}
