import React from 'react'
import styled from 'styled-components'
import { WeekHeadings } from '../WeekHeadings'
import { useCalendarViewContextStateValue } from '../context/CalendarViewProvider'
import { AsyncContent } from './AsyncContent'
import { ShiftRow } from './Shifts/ShiftRow'
import { WorkerRows } from './Workers/WorkerRows'

const Table = styled.table`
  width: 100%;

  border-spacing: 0;
  border-collapse: separate;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.neutrals[100]};

  & th:not(:last-child),
  & td:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals[100]};
  }

  & > tbody > tr:not(:last-child) > th,
  & > tbody > tr:not(:last-child) > td,
  & > thead > tr > th,
  & > thead:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals[100]};
  }

  & > thead > tr > th[data-active='true'] {
    border-bottom-color: ${({ theme }) => theme.colors.primary};
    border-bottom-width: 3px;
  }

  table-layout: fixed;
  width: 100%;
`

export const CalendarBody = () => {
  const {
    state: { queryStatus },
  } = useCalendarViewContextStateValue()

  return (
    <Table>
      <WeekHeadings />
      <tbody>
        <AsyncContent
          isLoading={queryStatus.isLoading}
          isError={queryStatus.isError}
        >
          <ShiftRow />
          <WorkerRows />
        </AsyncContent>
      </tbody>
    </Table>
  )
}
