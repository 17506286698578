import React, { useMemo } from 'react'
import { isAfter, addSeconds } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getTripsWithShareUrl } from '../../../lib/work'
import { getRosterWorkersOnShift } from '../../../lib/roster'
import { getShiftTimezone } from '../../../lib/shift'
import { Trip, Work } from '../../../typings/common_defs'
import { Text } from 'ui'
import { useShiftDetail } from '../useShiftDetail'

interface Props {
  work: Work
}

const ShiftDetailWorkerStatus = ({ work }: Props) => {
  const { shift } = useShiftDetail()
  const content = useMemo(() => {
    if (!shift) return null
    const fmt = 'h:mma zzz'
    const shifttz = getShiftTimezone(shift)

    const now = new Date()
    const shiftStartsAtDate = new Date(shift.startsAt)

    if (!work || !work.id) {
      return isAfter(shiftStartsAtDate, now) ? (
        <Text color={'neutrals.200'}>
          This spot is being offered to qualified workers
        </Text>
      ) : (
        <Text color={'neutrals.200'}>Unassigned</Text>
      )
    }

    if (work.status === 'started' && work.startedAt) {
      const workStartedAtDate = new Date(work.startedAt)
      return (
        <Text>
          Clocked in at {formatInTimeZone(workStartedAtDate, shifttz, fmt)}
        </Text>
      )
    }

    if (work.status === 'scheduled') {
      const trips: Array<Trip> = getTripsWithShareUrl(work)
      if (trips.length > 0) {
        const primaryTrip = trips[0]
        if (primaryTrip.locationStatus === 'traveling') {
          let displayedEta = ''
          if (primaryTrip.etaSeconds) {
            displayedEta = `ETA: ${formatInTimeZone(
              addSeconds(now, primaryTrip.etaSeconds),
              shifttz,
              fmt
            )}`
          } else if (primaryTrip.eta) {
            displayedEta = `ETA: ${formatInTimeZone(
              new Date(primaryTrip.eta),
              shifttz,
              fmt
            )}`
          }
          return <Text>{displayedEta}</Text>
        }
      }

      if (work.confirmedAt) {
        return (
          <Text>
            Confirmed at{' '}
            {formatInTimeZone(new Date(work.confirmedAt), shifttz, fmt)}
          </Text>
        )
      }

      const rosterWorkers = getRosterWorkersOnShift(shift)
      const rosterWorkerIds: Array<number> = rosterWorkers.map(
        (worker) => worker.id
      )
      if (work.worker && rosterWorkerIds.includes(work.worker.id)) {
        return <Text>Accepted</Text>
      }
    }

    if (
      work.status === 'removed' &&
      work.callouts?.at(-1)?.status === 'approved'
    ) {
      return <Text>Callout Approved</Text>
    }

    // TODO(Alex): add presumed bailed status (e.g. worker didn't clock in and shift has ended)

    return <Text>Not clocked in</Text>
  }, [work, shift])

  return content
}

export default ShiftDetailWorkerStatus
