export const companyKey = ['company']
export const companyInfoKey = [...companyKey, 'companyInfo']
export const companyLocationsKey = [...companyKey, 'companyLocations']
export const companyPositionsKey = [...companyKey, 'companyPositions']
export const companyRostersKey = [...companyKey, 'companyRosters']
export const companyAllWorkersKey = [...companyKey, 'companyWorkers']
export const companyWorkersKey = (
  search?: string,
  includeRosters?: boolean
) => [...companyAllWorkersKey, search, includeRosters]
export const companyShiftDetailKey = (ids: number[]) => [
  ...companyKey,
  'companyShiftDetail',
  ids,
]
export const companyAllRSWWorkersKey = [...companyKey, 'companyRSWWorkers']
export const companyRSWWorkersKey = (search?: string) => [
  ...companyKey,
  'companyRSWWorkers',
  search,
]
export const companyGroupMembersKey = [...companyKey, 'companyGroupMembers']
export const companyMembersKey = [...companyKey, 'companyMembers']
export const companyPendingInvitesKey = [...companyKey, 'companyPendingInvites']
export const companyRolesKey = [...companyKey, 'companyRoles']
export const positionTemplateGroupsKey = [
  ...companyKey,
  'positionTemplateGroups',
]
export const positionTemplateKey = (positionTemplateId?: number) => [
  ...companyKey,
  'positionTemplate',
  positionTemplateId,
]
export const companyLogsKey = (start: Date, end: Date) => [
  ...companyKey,
  'companyLogs',
  start.toISOString(),
  end.toISOString(),
]
export const companyTutorialsKey = [...companyKey, 'companyTutorials']
export const companyPositionRosterKey = (positionId: number) => [
  ...companyKey,
  companyPositionsKey,
  companyRostersKey,
  positionId,
]
export const companyPositionTutorialsKey = (positionId: number) => [
  ...companyKey,
  companyPositionsKey,
  positionId,
]
export const companyLocationTutorialsKey = (locationId: number) => [
  ...companyKey,
  companyLocationsKey,
  locationId,
]
export const locationTutorialKey = (
  locationId: number,
  tutorialId?: number
) => [...companyKey, companyLocationsKey, locationId, tutorialId]
export const positionTutorialKey = (
  positionId: number,
  tutorialId?: number
) => [...companyKey, companyPositionsKey, positionId, tutorialId]
export const positionTemplatesKey = [...companyKey, 'positionTemplates']
